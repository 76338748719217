import { useRef, useState } from "react";
import CrossIcon from "../icons/CrossIcon";
import "../styles/Modal.scss";
import { useTranslation } from "react-i18next";
import { useMask } from "@react-input/mask";
import { sendMessage } from "../api/telegram";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { getFormSubmissionCount, incrementFormSubmissionCount } from "../localStorageHelper";

const Modal = ({ showModal }: any) => {
  const { t, i18n } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const inputRef = useMask({ mask: "+__(___)-___-__-__", replacement: { _: /\d/ } });
  const toast = useRef<any>(null);
  const showSuccess = () => {
    if (toast.current) {
      toast.current.show({
        severity: "success",
        summary: i18n.language === "en" ? "Success!" : "Вітання!",
        detail: i18n.language === "en" ? "Your message has been sent" : "Ваше повідомлення надіслано",
        life: 2500,
      });
    }
  };
  const showError = () => {
    if (toast.current) {
      toast.current.show({
        severity: "error",
        summary: i18n.language === "en" ? "Error!" : "Увага!",
        detail: i18n.language === "en" ? "An error occurred" : "Виникла помилка",
        life: 2500,
      });
    }
  };
  const showLimit = () => {
    if (toast.current) {
      toast.current.show({
        severity: "error",
        summary: i18n.language === "en" ? "You have exhausted your attempts!" : "Ви вичерпали спроби!",
        detail: i18n.language === "en" ? "Try again later" : "Спробуйте пізніше",
        life: 2500,
      });
    }
  };
  const productsEng = [
    { name: "Millet", code: "Millet" },
    { name: "Coriander Seeds", code: "Coriander Seeds" },
    { name: "Peas", code: "Peas" },
    { name: "Sorghum", code: "Sorghum" },
    { name: "Lentils", code: "Lentils" },
    { name: "Flaxseeds", code: "Flaxseeds" },
    { name: "Rapeseeds", code: "Rapeseeds" },
    { name: "Sunflower seeds", code: "Sunflower seeds" },
    { name: "Oats", code: "Oats" },
  ];
  const productsUa = [
    { name: "Просо", code: "Просо" },
    { name: "Коріандр", code: "Коріандр" },
    { name: "Горох", code: "Горох" },
    { name: "Cорго", code: "Cорго" },
    { name: "Cочевиця", code: "Cочевиця" },
    { name: "Льон", code: "Льон" },
    { name: "Ріпак", code: "Ріпак" },
    { name: "Cоняшник", code: "Cоняшник" },
    { name: "Овес", code: "Овес" },
  ];

  const showModalMessage = () => {
    showModal();
  };

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const submissionCount = getFormSubmissionCount();

    if (submissionCount >= 3) {
      showLimit();
      return;
    }

    try {
      if (formValues.name && formValues.phone && formValues.email && formValues.description && selectedProducts) {
        let prods = "";
        for (let i = 0; i < selectedProducts.length; i++) {
          prods += selectedProducts[i].name + ",";
        }

        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Email: ${formValues.email} %0A- Додаткова інформація: ${formValues.description}%0A- Продукти: ${prods}`;
        await sendMessage(message);
        showSuccess();
        showModalMessage();
        incrementFormSubmissionCount();
      } else {
        showError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
    }
  };

  return (
    <div className="Modal">
      <Toast ref={toast} />
      <div className="ModalOverlay" onClick={showModalMessage}></div>
      <div className="ModalBody">
        <div className="ModalHeader">
          <h4 style={{ margin: "10px 0px" }}>{t("modalTitle")}</h4>
          <button onClick={showModalMessage}>
            <CrossIcon></CrossIcon>
          </button>
        </div>
        <div className="ModalContent">
          <form action="submit">
            <input
              id="name"
              type="text"
              name="name"
              className="FormInput"
              placeholder={t("footerInput1")}
              value={formValues.name}
              onChange={handleChange}
              required
            />
            <input
              className="FormInput"
              placeholder={t("footerInput2")}
              ref={inputRef}
              type="tel"
              name="phone"
              required
              value={formValues.phone}
              onChange={handleChange}
            />
            <input
              type="text"
              className="FormInput"
              placeholder="Email"
              name="email"
              required
              value={formValues.email}
              onChange={handleChange}
            />
            <input
              type="text"
              className="FormInput"
              placeholder={t("footerInput4")}
              name="description"
              required
              value={formValues.description}
              onChange={handleChange}
            />
            <MultiSelect
              value={selectedProducts}
              onChange={(e) => setSelectedProducts(e.value)}
              options={i18n.language === "ua" ? productsUa : productsEng}
              optionLabel="name"
              placeholder={t("footerInput3")}
              itemClassName="FormMultiInput"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />

            <button className="FormButton" type="submit" onClick={handleSubmit}>
              {t("footerInputButton")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
