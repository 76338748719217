import { useEffect, useState } from "react";
import "../styles/Languages.scss";
import BurgerIcon from "../icons/BurgerIcon";
import ModalHeader from "./ModalHeader";
import i18n from "../i18n";

const Languages = () => {
  const [changeStructure, setChangeStructure] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 800) {
      setChangeStructure(false);
      setShowModal(false);
    } else if (width <= 800) {
      setChangeStructure(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    console.log(lng);
  };

  return (
    <div className="Languages" id="home">
      <div className="LanguagesBody">
        <button onClick={() => changeLanguage("ua")}>
          <img src="/ukraine.webp" alt="" />
        </button>
        <button onClick={() => changeLanguage("en")}>
          <img src="/usa.webp" alt="" />
        </button>
      </div>
      {changeStructure && (
        <button
          className="LanguagesModalButton"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <BurgerIcon></BurgerIcon>
        </button>
      )}
      {changeStructure && showModal && <ModalHeader hideModal={hideModal}></ModalHeader>}
    </div>
  );
};

export default Languages;
