import { useTranslation } from "react-i18next";
import LocationIcon from "../icons/LocationIcon";
import MaiLIcon from "../icons/MaiLIcon";
import PhoneIcon from "../icons/PhoneIcon";
import TelegramIcon from "../icons/TelegramIcon";
import WhatsappIcon from "../icons/WhatsappIcon";
import "../styles/Footer.scss";
import { MultiSelect } from "primereact/multiselect";
import { useRef, useState } from "react";
import { useMask } from "@react-input/mask";
import { sendMessage } from "../api/telegram";
import { Toast } from "primereact/toast";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const inputRef = useMask({ mask: "+__(___)-___-__-__", replacement: { _: /\d/ } });
  const toast = useRef<any>(null);
  const showSuccess = () => {
    if (toast.current) {
      toast.current.show({
        severity: "success",
        summary: i18n.language === "en" ? "Success!" : "Вітання!",
        detail: i18n.language === "en" ? "Your message has been sent" : "Ваше повідомлення надіслано",
        life: 2500,
      });
    }
  };
  const showError = () => {
    if (toast.current) {
      toast.current.show({
        severity: "error",
        summary: i18n.language === "en" ? "Error!" : "Увага!",
        detail: i18n.language === "en" ? "An error occurred" : "Виникла помилка",
        life: 2500,
      });
    }
  };
  const productsEng = [
    { name: "Millet", code: "Millet" },
    { name: "Coriander Seeds", code: "Coriander Seeds" },
    { name: "Peas", code: "Peas" },
    { name: "Sorghum", code: "Sorghum" },
    { name: "Lentils", code: "Lentils" },
    { name: "Flaxseeds", code: "Flaxseeds" },
    { name: "Rapeseeds", code: "Rapeseeds" },
    { name: "Sunflower seeds", code: "Sunflower seeds" },
    { name: "Oats", code: "Oats" },
  ];
  const productsUa = [
    { name: "Просо", code: "Просо" },
    { name: "Коріандр", code: "Коріандр" },
    { name: "Горох", code: "Горох" },
    { name: "Cорго", code: "Cорго" },
    { name: "Cочевиця", code: "Cочевиця" },
    { name: "Льон", code: "Льон" },
    { name: "Ріпак", code: "Ріпак" },
    { name: "Cоняшник", code: "Cоняшник" },
    { name: "Овес", code: "Овес" },
  ];

  const scrollToHome = () => {
    const aboutElement = document.getElementById("home") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAbout = () => {
    const aboutElement = document.getElementById("about") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAdvantages = () => {
    const aboutElement = document.getElementById("advantages") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToServices = () => {
    const aboutElement = document.getElementById("services") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (formValues.name && formValues.phone && formValues.email && formValues.description && selectedProducts) {
        let prods = "";
        for (let i = 0; i < selectedProducts.length; i++) {
          prods += selectedProducts[i].name + ",";
        }

        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Email: ${formValues.email} %0A- Додаткова інформація: ${formValues.description} %0A- Продукти: ${prods}`;
        await sendMessage(message);
        showSuccess();
      } else {
        showError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
    }
  };

  return (
    <div className="Footer" id="footer">
      <Toast ref={toast} />
      <div className="FooterBody FooterParallax">
        <div className="FooterContentBody">
          <div className="FooterHead">
            <div className="FooterLogoBG">
              <img src="/Terra_Group_Logo.svg" alt="" />
            </div>
            <h2 className="FooterTitle">
              <span>Terra Group</span>
            </h2>
          </div>
          <div className="FooterContent">
            <div className="FooterSide">
              <form action="submit">
                <input
                  id="name"
                  type="text"
                  name="name"
                  className="FormInput"
                  placeholder={t("footerInput1")}
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
                <input
                  className="FormInput"
                  placeholder={t("footerInput2")}
                  ref={inputRef}
                  type="tel"
                  name="phone"
                  required
                  value={formValues.phone}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="FormInput"
                  placeholder="Email"
                  name="email"
                  required
                  value={formValues.email}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="FormInput"
                  placeholder={t("footerInput4")}
                  name="description"
                  required
                  value={formValues.description}
                  onChange={handleChange}
                />
                <MultiSelect
                  value={selectedProducts}
                  onChange={(e) => setSelectedProducts(e.value)}
                  options={i18n.language === "ua" ? productsUa : productsEng}
                  optionLabel="name"
                  placeholder={t("footerInput3")}
                  itemClassName="FormMultiInput"
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                />

                <button className="FormButton" type="submit" onClick={handleSubmit}>
                  {t("footerInputButton")}
                </button>
              </form>
            </div>
            <div className="FooterSide">
              <div className="FooterNav">
                <button className="FooterNavButtons" style={{ width: "100px" }} onClick={scrollToHome}>
                  <p>{t("headerNav1")}</p>
                </button>
                <button className="FooterNavButtons" style={{ width: "95px" }} onClick={scrollToAbout}>
                  <p>{t("headerNav2")}</p>
                </button>
                <button className="FooterNavButtons" onClick={scrollToAdvantages} style={{ width: "170px" }}>
                  <p>{t("headerNav3")}</p>
                </button>
                <button className="FooterNavButtons" style={{ width: "150px" }} onClick={scrollToServices}>
                  <p>{t("headerNav4")}</p>
                </button>
              </div>
            </div>
            <div className="FooterSide">
              <div className="FooterContacts">
                <h3 className="FooterContactsTitle">{t("footerContactTitle")}</h3>
                <div className="FooterContactsContent">
                  <div className="FooterContactsIcon">
                    <PhoneIcon></PhoneIcon>
                    <h4>
                      <a href="tel:+380668889904" style={{ textDecoration: "none", color: "#404040" }}>
                        +38(066)-88-89-904
                      </a>
                      <a href="tel:+380968889904" style={{ textDecoration: "none", color: "#404040" }}>
                        +38(096)-88-89-904
                      </a>
                    </h4>
                  </div>
                  <div className="FooterContactsIcon">
                    <MaiLIcon></MaiLIcon>
                    <div>
                      <h4>
                        <a
                          href="mailto:office@terragroup-ukraine.com"
                          style={{ textDecoration: "none", color: "#404040" }}
                        >
                          office@terragroup-ukraine.com
                        </a>
                      </h4>
                      <h4>
                        <a
                          href="mailto:sales.manager@terragroup-ukraine.com"
                          style={{ textDecoration: "none", color: "#404040" }}
                        >
                          sales.manager@terragroup-ukraine.com
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="FooterLocation">
                  <LocationIcon></LocationIcon>
                  <h4>{t("footerContactLocation")}</h4>
                </div>
                <div className="FooterSocial">
                  <button className="FooterSocialButton">
                    <a href="https://t.me/+380968889904" rel="noopener noreferrer">
                      <TelegramIcon></TelegramIcon>
                    </a>
                  </button>
                  <button className="FooterSocialButton">
                    <a href="https://wa.me/380968889904" rel="noopener noreferrer">
                      <WhatsappIcon></WhatsappIcon>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
