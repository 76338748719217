import { useTranslation } from "react-i18next";
import "../styles/Services.scss";

const Services = () => {
  const { t } = useTranslation();
  return (
    <div className="Services" id="services">
      <p className="ServicesSmall">{t("servicesSmall")}</p>
      <h2 className="ServicesTitle">{t("servicesBig")}</h2>
      <div className="ServicesCards">
        <div className="ServicesCard">
          <div className="ServicesCardBody">
            <img src="/services1.webp" alt="" />
            <div className="ServicesCardFooter">
              <h2>{t("service1")}</h2>
              <p>{t("service1Text")}</p>
            </div>
          </div>
        </div>
        <div className="ServicesCard">
          <div className="ServicesCardBody">
            <img src="/service2.webp" alt="" />
            <div className="ServicesCardFooter">
              <h2>{t("service2")}</h2>
              <p>{t("service2TextFirst")}</p>
              <p>{t("service2TextSecond")}</p>
            </div>
          </div>
        </div>
        <div className="ServicesCard">
          <div className="ServicesCardBody">
            <img src="/services3.webp" alt="" />
            <div className="ServicesCardFooter">
              <h2>{t("service3")}</h2>
              <p>{t("service3Text")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
