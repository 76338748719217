import { useTranslation } from "react-i18next";
import "../styles/Main.scss";

const Main = ({ showModal }: any) => {
  const { t } = useTranslation();
  const scrollToAbout = () => {
    const aboutElement = document.getElementById("about") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAdvantages = () => {
    const aboutElement = document.getElementById("advantages") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToServices = () => {
    const aboutElement = document.getElementById("services") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFooter = () => {
    const aboutElement = document.getElementById("footer") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };

  const showModalMessage = () => {
    showModal();
  };

  return (
    <div className="Main">
      <video autoPlay muted loop preload="auto" className="Main_video" poster="terragroup-poster.webp" controls={false}>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/terra-group-1f2db.appspot.com/o/MainBG_3.webm?alt=media&token=9ae14692-3fa4-4058-b5c4-2a2e61e8a5cf"
          type="video/webm"
        />
      </video>
      <div className="Header">
        <button className="HeaderButton" onClick={scrollToAbout}>
          <p>{t("headerNav2")}</p>
        </button>
        <button className="HeaderButton" onClick={scrollToAdvantages}>
          <p>{t("headerNav3")}</p>
        </button>
        <div className="HeaderLogoContainer">
          <div className="HeaderLogoBG">
            <img src="/Terra_Group_Logo.svg" alt="" />
          </div>
        </div>
        <button className="HeaderButton" onClick={scrollToServices}>
          <p>{t("headerNav4")}</p>
        </button>
        <button className="HeaderButton" onClick={scrollToFooter}>
          <p>{t("headerNav5")}</p>
        </button>
      </div>
      <div className="MainText">
        <h1>
          <span>Terra Group</span>
        </h1>
        <h4>{t("mainDescription")}</h4>
        <button className="MainButton" onClick={showModalMessage}>
          {t("mainButton")}
        </button>
      </div>
    </div>
  );
};

export default Main;
