import { useTranslation } from "react-i18next";
import "../styles/Products.scss";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const Card = (index: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const positioned = index.index % 2 === 0 ? "left" : "right";
  const { i18n } = useTranslation();
  const [animationVars, setAnimationVars] = useState(1500);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1920) {
      setAnimationVars(1500);
    } else if (width < 1920 && width >= 650) {
      setAnimationVars(500);
    } else if (width < 650) {
      setAnimationVars(200);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && ref.current) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.2 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const animationVariants = {
    hidden: { opacity: 0, x: index.index % 2 === 0 ? -animationVars : animationVars },
    visible: { opacity: 1, x: 0 },
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={animationVariants}
      transition={{ duration: 0.8 }}
      className={`ProductCard ${positioned}`}
    >
      <div className="ProductCardImage">
        <img src={index.item.image} alt="" />
      </div>
      <div className="ProductCardDescription">
        <h2>{i18n.language === "en" ? index.item.titleEng : index.item.titleUkr}</h2>
        <p>{i18n.language === "en" ? index.item.descriptionEng1 : index.item.descriptionUkr1}</p>
        <p>{i18n.language === "en" ? index.item.descriptionEng2 : index.item.descriptionUkr2}</p>
      </div>
    </motion.div>
  );
};

const Products = () => {
  const { t } = useTranslation();

  return (
    <div className="Products">
      <p className="ProductsSmall">{t("productsSmall")}</p>
      <h2 className="ProductsTitle">{t("productsBig")}</h2>
      <div className="ProductsBody">
        {products.map((data, index) => (
          <Card key={index} index={index} item={data} />
        ))}
      </div>
    </div>
  );
};

export default Products;

const products = [
  {
    id: 1,
    titleUkr: "Просо",
    descriptionUkr1: "Волога: 14% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Millet",
    descriptionEng1: "Moisture: 14% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product1.webp",
  },
  {
    id: 2,
    titleUkr: "Коріандр",
    descriptionUkr1: "Волога: 9% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Coriander Seeds",
    descriptionEng1: "Moisture: 9% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product4.webp",
  },
  {
    id: 3,
    titleUkr: "Горох",
    descriptionUkr1: "Волога: 14% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Peas",
    descriptionEng1: "Moisture: 14% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product7.webp",
  },
  {
    id: 4,
    titleUkr: "Cорго",
    descriptionUkr1: "Волога: 14% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Sorghum",
    descriptionEng1: "Moisture: 14% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product5.webp",
  },
  {
    id: 5,
    titleUkr: "Cочевиця",
    descriptionUkr1: "Волога: 14% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Lentils",
    descriptionEng1: "Moisture: 14% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product6.webp",
  },
  {
    id: 6,
    titleUkr: "Льон",
    descriptionUkr1: "Волога: 9% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Flaxseeds",
    descriptionEng1: "Moisture: 9% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product2.webp",
  },
  {
    id: 7,
    titleUkr: "Ріпак",
    descriptionUkr1: "Волога: 9% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Rapeseeds",
    descriptionEng1: "Moisture: 9% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product3.webp",
  },
  {
    id: 8,
    titleUkr: "Cоняшник",
    descriptionUkr1: "Волога: 9% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Sunflower seeds",
    descriptionEng1: "Moisture: 9% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product8.webp",
  },
  {
    id: 9,
    titleUkr: "Овес",
    descriptionUkr1: "Волога: 14% максимум",
    descriptionUkr2: "Чистота: 98% мінімум",
    titleEng: "Oats",
    descriptionEng1: "Moisture: 14% max",
    descriptionEng2: "Purity: 98% min",
    image: "./products/product9.webp",
  },
];
