import { useTranslation } from "react-i18next";
import "../styles/Advantages.scss";

const Advantages = () => {
  const { t } = useTranslation();
  return (
    <div className="Advantages" id="advantages">
      <div
        className="AdvantagesBody parallax"
        style={{
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/terra-group-1f2db.appspot.com/o/advantagesBG.webp?alt=media&token=947a252d-39aa-4287-b4d7-626a561a5359)",
        }}
      >
        <p className="AdvantagesSmall">{t("advantagesSmall")}</p>
        <h2 className="AdvantagesTitle">{t("advantagesBig")}</h2>
        <div className="AdvantagesCards">
          <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">1.</div>
            <h5>{t("advantagesDescTitle_1")}</h5>
            <p>{t("advantagesDesc_1")}</p>
          </div>
          <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">2.</div>
            <h5>{t("advantagesDescTitle_2")}</h5>
            <p>{t("advantagesDesc_2")}</p>
          </div>
          {/* <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">3.</div>
            <h5>{t("advantagesDescTitle_3")}</h5>
            <p>{t("advantagesDesc_3")}</p>
          </div> */}
          <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">3.</div>
            <h5>{t("advantagesDescTitle_4")}</h5>
            <p>{t("advantagesDesc_4")}<br />{t("advantagesDesc_6")}</p>
          </div>
          <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">4.</div>
            <h5>{t("advantagesDescTitle_5")}</h5>
            <p>{t("advantagesDesc_5")}</p>
          </div>
          {/* <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">6.</div>
            <h5>{t("advantagesDescTitle_6")}</h5>
            <p>{t("advantagesDesc_6")}</p>
          </div> */}
          {/* <div className="AdvantagesCard">
            <div className="AdvantagesCardHeader">7.</div>
            <h5>{t("advantagesDescTitle_7")}</h5>
            <p>{t("advantagesDesc_7")}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Advantages;
