import { useTranslation } from "react-i18next";
import "../styles/Credits.scss";
import i18n from "../i18n";

const Credits = () => {
  const { t } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    console.log(lng);
  };

  return (
    <div className="Credits">
      <div className="CreditsBody">
        <div className="CreditsData">{t("rights")}</div>
        <div className="CreditsLanguages">
          <button onClick={() => changeLanguage("ua")}>
            <img src="/ukraine.webp" alt="" />
          </button>
          <button onClick={() => changeLanguage("en")}>
            <img src="/usa.webp" alt="" />
          </button>
        </div>
        <div className="CreditsMadeBy">
          <a href="https://abc-digital.com/">Developed by ABC Digital</a>
        </div>
      </div>
    </div>
  );
};

export default Credits;
