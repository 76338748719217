import { useTranslation } from "react-i18next";
import WheatIcon from "../icons/WheatIcon";
import "../styles/About.scss";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="About" id="about">
      <p className="AboutSmall"> {t("aboutSmall")}</p>

      <h2 className="AboutTitle">{t("aboutBig1")}<br /><br />{t("aboutBig2")}</h2>
      <div className="AboutAdvantages">
        <div className="BlockAdvantagesBody">
          <WheatIcon></WheatIcon>
          <div className="BlockAdvantages">
            <h5> {t("aboutTitle")}</h5>
            <p> {t("aboutDescription")}</p>
          </div>
        </div>
        {/* <div className="BlockAdvantagesBody">
          <RoadIcon></RoadIcon>
          <div className="BlockAdvantages">
            <h5>Швидка логістика</h5>
            <p>
              Наші успіхи на міжнародній арені підтверджують здатність компанії відповідати і перевищувати високі
              світові стандарти в агробізнесі, ставлячи за планку високу швидкість доставки і побудову сучасної
              логістики!
            </p>
          </div>
        </div> */}
      </div>
      <div className="AboutVideoSection">
        <div className="AboutVideoBody">
          <video muted controls preload="auto" className="About_video">
            <source src="/MainBG.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default About;
