import { useEffect, useState } from "react";
import "./App.scss";
import About from "./components/About";
import Advantages from "./components/Advantages";
import Credits from "./components/Credits";
import Footer from "./components/Footer";
import Languages from "./components/Languages";
import Main from "./components/Main";
import Services from "./components/Services";
import ShipIcon from "./icons/ShipIcon";
import Products from "./components/Products";
import { PrimeReactProvider } from "primereact/api";
import Modal from "./components/Modal";

function App() {
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadBody, setIsLoadBody] = useState(true);
  const [isSHowModal, setIsSHowModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 11000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoadBody(false);
    }, 100);
  }, []);

  const showModal = () => {
    setIsSHowModal(!isSHowModal);
  };

  return (
    <div className="App">
      {isLoad === true ? (
        <div className="loader">
          <div className="truckWrapper">
            <video
              autoPlay
              muted
              preload="auto"
              className="Main_video"
              controls={false}
              playsInline
              webkit-playsinline
              poster="https://firebasestorage.googleapis.com/v0/b/terra-group-1f2db.appspot.com/o/Terra%201080X1920%20New.mp4?alt=media&token=c6598c92-067b-434b-a3e0-8135826c8f18"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/terra-group-1f2db.appspot.com/o/Terra%201080X1920%20New.mp4?alt=media&token=c6598c92-067b-434b-a3e0-8135826c8f18"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isLoadBody === true ? (
        <></>
      ) : (
        <>
          <PrimeReactProvider>
            {isSHowModal && <Modal showModal={showModal}></Modal>}
            <Languages></Languages>
            <Main showModal={showModal}></Main>
            <About></About>
            <Products></Products>
            <Advantages></Advantages>
            <Services></Services>
            <Footer></Footer>
            <Credits></Credits>
          </PrimeReactProvider>
        </>
      )}
    </div>
  );
}

export default App;
