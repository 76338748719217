import { useEffect, useRef } from "react";
import "../styles/ModalHeader.scss";
import CrossIcon from "../icons/CrossIcon";
import PhoneIcon from "../icons/PhoneIcon";
import MaiLIcon from "../icons/MaiLIcon";
import { useTranslation } from "react-i18next";

const ModalHeader = ({ hideModal }: any) => {
  const modal = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const scrollToHome = () => {
    const aboutElement = document.getElementById("home") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
    closeModal();
  };
  const scrollToAbout = () => {
    const aboutElement = document.getElementById("about") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
    closeModal();
  };
  const scrollToAdvantages = () => {
    const aboutElement = document.getElementById("advantages") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
    closeModal();
  };
  const scrollToServices = () => {
    const aboutElement = document.getElementById("services") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
    closeModal();
  };
  const scrollToFooter = () => {
    const aboutElement = document.getElementById("footer") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
    closeModal();
  };

  const closeModal = () => {
    if (modal) {
      setTimeout(() => {
        modal.current?.classList.add("closed");
      }, 50);
      setTimeout(() => {
        hideModal();
      }, 150);
    }
  };

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        modal.current?.classList.remove("closed");
      }, 50);
    }
  }, []);

  return (
    <div className="ModalHeader">
      <div className="ModalHeaderOverlay" onClick={closeModal}></div>
      <div className="ModalHeaderContent closed" ref={modal}>
        <div className="ModalHeaderLogo">
          <div className="ModalLogoContainer">
            <div className="HeaderLogoBG">
              <img src="/Terra_Group_Logo.svg" alt="" />
            </div>
          </div>
          <button className="ModalHeaderClose" onClick={closeModal}>
            <CrossIcon></CrossIcon>
          </button>
        </div>
        <div className="ModalHeaderBody">
          <div className="ModalHeaderRoutes">
            <button className="ModalNavButton" style={{ width: "100px" }} onClick={scrollToHome}>
              <p>{t("headerNav1")}</p>
            </button>
            <button className="ModalNavButton" style={{ width: "100px" }} onClick={scrollToAbout}>
              <p>{t("headerNav2")}</p>
            </button>
            <button className="ModalNavButton" style={{ width: "170px" }} onClick={scrollToAdvantages}>
              <p>{t("headerNav3")}</p>
            </button>
            <button className="ModalNavButton" style={{ width: "150px" }} onClick={scrollToServices}>
              <p>{t("headerNav4")}</p>
            </button>
            <button className="ModalNavButton" style={{ width: "100px" }} onClick={scrollToFooter}>
              <p>{t("headerNav5")}</p>
            </button>
          </div>
          <div className="ModalHeaderContacts">
            <div className="lineBody">
              <div className="line"></div>
            </div>
            <div className="ModalContactsIcon">
              <PhoneIcon></PhoneIcon>
              <h4>
                <a href="tel:+380668889904" style={{ textDecoration: "none", color: "#404040" }}>
                  +38(066)-88-89-904
                </a>
                <a href="tel:+380968889904" style={{ textDecoration: "none", color: "#404040" }}>
                  +38(096)-88-89-904
                </a>
              </h4>
            </div>
            <div className="ModalContactsIcon">
              <MaiLIcon></MaiLIcon>
              <div>
                <h4>office@terragroup-ukraine.com</h4>
                <h4>sales.manager@terragroup-ukraine.com</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
