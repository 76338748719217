const WheatIcon = () => {
  return (
    <svg height="110px" width="110px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g>
        <path
          className="st0"
          d="M238.536,189.975c3.985,4.892,8.617,9.067,14.021,11.994l4.377,2.368l4.38-2.368
     c5.401-2.928,10.033-7.103,14.02-11.994c5.969-7.36,10.556-16.405,13.76-26.267c3.188-9.856,4.97-20.547,4.973-31.221
     c-0.006-14.239-3.188-27.532-8.818-38.726c-5.636-11.172-13.749-20.351-23.936-25.886l-4.38-2.368l-4.377,2.368
     c-10.186,5.535-18.302,14.714-23.938,25.886c-5.631,11.194-8.812,24.487-8.818,38.726c0.014,14.234,3.148,28.489,8.639,40.78
     C231.193,179.407,234.551,185.072,238.536,189.975z M245.048,102.04c3.221-6.42,7.377-11.536,11.885-15.09
     c4.511,3.554,8.664,8.676,11.885,15.09c4.251,8.423,6.86,18.974,6.854,30.448c0.017,11.469-2.642,23.491-7.032,33.258
     c-2.186,4.886-4.791,9.201-7.578,12.621c-1.349,1.662-2.737,3.095-4.128,4.315c-1.388-1.22-2.779-2.653-4.128-4.315
     c-4.187-5.127-7.937-12.28-10.531-20.323c-2.608-8.043-4.086-16.954-4.08-25.556C238.189,121.014,240.797,110.463,245.048,102.04z"
          fill="#404040"
        />
        <path
          fill="#404040"
          className="st0"
          d="M231.327,209.284c-5.054-8.648-11.152-16.701-18.084-23.205c-7.903-7.399-16.665-13.08-25.69-16.937
     c-9.025-3.85-18.319-5.904-27.336-5.91c-5.678,0-11.256,0.822-16.522,2.58l-4.722,1.573l-1.265,4.819
     c-1.097,4.181-1.618,8.524-1.618,12.924c0.006,9.56,2.432,19.377,6.904,28.797c4.478,9.414,11.023,18.442,19.472,26.356
     c8.703,8.139,19.405,15.862,30.579,21.739c5.591,2.933,11.3,5.401,17.006,7.176c5.709,1.769,11.412,2.866,17.082,2.871
     c3.739,0,7.475-0.486,11.085-1.69l4.724-1.573l1.265-4.819c0.859-3.274,1.206-6.655,1.208-10.092
     c-0.006-5.726-0.968-11.653-2.6-17.675C240.358,227.19,236.373,217.943,231.327,209.284z M215.509,251.632
     c-6.59-2.043-13.978-5.597-21.05-9.985c-7.078-4.377-13.864-9.582-19.419-14.788c-6.806-6.38-11.992-13.59-15.434-20.832
     c-3.448-7.243-5.127-14.496-5.121-20.9c0-1.052,0.061-2.082,0.148-3.084c1.777-0.274,3.644-0.414,5.583-0.414
     c6.193-0.005,13.133,1.439,20.107,4.428c6.977,2.977,13.976,7.478,20.342,13.444c7.08,6.604,13.92,16.422,18.742,26.546
     c2.421,5.06,4.354,10.193,5.656,14.995c1.282,4.69,1.928,9.061,1.948,12.621C223.816,253.658,219.844,252.981,215.509,251.632z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M269.182,236.218c-1.632,6.022-2.594,11.949-2.6,17.675c0.003,3.437,0.35,6.818,1.209,10.092l1.265,4.819
     l4.724,1.573c3.61,1.204,7.347,1.69,11.082,1.69c5.67-0.005,11.376-1.102,17.085-2.871c8.552-2.664,17.138-6.874,25.276-11.905
     c8.13-5.037,15.784-10.898,22.31-17.01l0.025-0.022l1.654-1.55l-1.64,1.534c8.426-7.909,14.964-16.92,19.433-26.317
     c4.472-9.42,6.898-19.237,6.904-28.797c0-4.399-0.52-8.742-1.617-12.924l-1.266-4.819l-4.721-1.573
     c-5.267-1.758-10.844-2.58-16.522-2.58c-9.017,0.006-18.314,2.06-27.336,5.91c-9.025,3.856-17.787,9.538-25.688,16.931
     c-9.235,8.682-17.029,20.094-22.762,32.038C273.142,224.083,270.819,230.195,269.182,236.218z M286.934,241.042
     c1.95-7.204,5.339-15.146,9.627-22.49c4.278-7.343,9.47-14.093,14.773-19.052c6.364-5.966,13.366-10.467,20.342-13.444
     c6.974-2.989,13.914-4.433,20.108-4.428c1.939,0,3.806,0.14,5.583,0.414c0.087,1.002,0.148,2.032,0.148,3.084
     c0.006,6.403-1.674,13.657-5.122,20.9c-3.442,7.242-8.627,14.452-15.436,20.832l-0.031,0.028
     c-7.399,6.935-16.99,13.853-26.521,18.846c-4.769,2.507-9.523,4.533-13.915,5.899c-4.337,1.349-8.309,2.026-11.502,2.032
     C285.008,250.104,285.654,245.732,286.934,241.042z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M231.327,309.309c-5.054-8.648-11.152-16.702-18.086-23.211c-7.901-7.394-16.663-13.075-25.685-16.931
     c-9.025-3.856-18.322-5.905-27.336-5.911c-5.681,0-11.259,0.823-16.528,2.58l-4.718,1.578l-1.265,4.814
     c-1.097,4.186-1.618,8.524-1.618,12.918c0.006,9.565,2.432,19.382,6.904,28.802c4.478,9.414,11.023,18.437,19.472,26.356
     c8.703,8.138,19.402,15.862,30.579,21.739c5.591,2.933,11.3,5.401,17.006,7.175c5.709,1.769,11.412,2.86,17.082,2.872
     c3.739,0,7.475-0.493,11.085-1.69l4.724-1.578l1.265-4.814c0.859-3.28,1.206-6.649,1.208-10.091
     c-0.006-5.726-0.968-11.653-2.6-17.681C240.358,327.214,236.373,317.968,231.327,309.309z M215.509,351.651
     c-6.59-2.038-13.978-5.592-21.05-9.974c-7.075-4.382-13.864-9.587-19.416-14.798c-6.809-6.375-11.995-13.59-15.437-20.832
     c-3.448-7.242-5.127-14.496-5.121-20.899c0-1.052,0.061-2.071,0.148-3.078c1.777-0.274,3.644-0.414,5.586-0.414
     c6.19-0.006,13.13,1.444,20.104,4.428c6.977,2.978,13.976,7.483,20.342,13.444c7.08,6.604,13.92,16.416,18.742,26.547
     c2.421,5.06,4.354,10.192,5.656,14.988c1.282,4.696,1.928,9.068,1.948,12.627C223.816,353.682,219.844,353.005,215.509,351.651z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M373.027,267.415l-4.718-1.578c-5.27-1.757-10.847-2.58-16.528-2.58c-9.014,0.006-18.31,2.054-27.336,5.911
     c-9.022,3.856-17.784,9.537-25.685,16.931c-9.232,8.68-17.029,20.093-22.762,32.037c-2.855,5.972-5.178,12.084-6.815,18.101
     c-1.632,6.028-2.594,11.956-2.6,17.681c0.003,3.442,0.35,6.812,1.209,10.091l1.265,4.814l4.724,1.578
     c3.61,1.197,7.347,1.69,11.082,1.69c5.67-0.012,11.376-1.103,17.085-2.872c8.552-2.664,17.138-6.879,25.276-11.904
     c8.13-5.038,15.786-10.904,22.31-17.01c8.449-7.92,14.994-16.942,19.472-26.356c4.472-9.42,6.898-19.237,6.904-28.802
     c0-4.394-0.52-8.732-1.617-12.918L373.027,267.415z M352.393,306.046c-3.442,7.243-8.627,14.458-15.436,20.832
     c-7.402,6.952-17.006,13.875-26.552,18.879c-4.769,2.502-9.523,4.534-13.915,5.894c-4.337,1.354-8.309,2.032-11.502,2.037
     c0.02-3.559,0.666-7.93,1.945-12.627c1.95-7.197,5.339-15.14,9.627-22.478c4.278-7.349,9.47-14.104,14.773-19.058
     c6.364-5.961,13.366-10.466,20.342-13.444c6.974-2.984,13.914-4.433,20.104-4.428c1.942,0,3.809,0.14,5.586,0.414
     c0.087,1.007,0.148,2.026,0.148,3.078C357.52,291.55,355.841,298.804,352.393,306.046z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M231.327,408.181c-5.054-8.647-11.152-16.702-18.086-23.206c-7.901-7.399-16.663-13.074-25.685-16.936
     c-9.025-3.851-18.322-5.9-27.336-5.905c-5.681,0-11.259,0.822-16.528,2.58l-4.718,1.572l-1.265,4.813
     c-1.097,4.187-1.618,8.53-1.618,12.924c0.006,9.566,2.432,19.383,6.904,28.802c4.478,9.409,11.023,18.437,19.472,26.351
     c8.703,8.138,19.405,15.862,30.579,21.738c5.591,2.934,11.303,5.407,17.006,7.176c5.709,1.768,11.415,2.866,17.085,2.871
     c3.736,0,7.469-0.487,11.08-1.685l4.726-1.578l1.265-4.813c0.859-3.28,1.206-6.655,1.208-10.092
     c-0.006-5.726-0.968-11.658-2.6-17.681C240.358,426.086,236.373,416.845,231.327,408.181z M215.509,450.528
     c-6.59-2.042-13.975-5.597-21.047-9.979c-7.078-4.382-13.866-9.588-19.422-14.793c-6.806-6.381-11.992-13.59-15.434-20.832
     c-3.448-7.243-5.127-14.496-5.121-20.9c0-1.052,0.061-2.076,0.148-3.084c1.777-0.268,3.644-0.414,5.586-0.414
     c6.19-0.006,13.13,1.444,20.104,4.427c6.977,2.978,13.976,7.484,20.342,13.444c7.08,6.611,13.92,16.422,18.742,26.547
     c2.421,5.06,4.354,10.192,5.656,14.994c1.282,4.696,1.928,9.061,1.948,12.627C223.816,452.554,219.848,451.882,215.509,450.528z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M373.027,366.287l-4.718-1.572c-5.27-1.758-10.847-2.58-16.528-2.58c-9.014,0.006-18.31,2.054-27.336,5.905
     c-9.022,3.862-17.784,9.537-25.685,16.936c-9.232,8.676-17.029,20.088-22.762,32.032c-2.855,5.978-5.178,12.084-6.815,18.106
     c-1.632,6.023-2.594,11.955-2.6,17.681c0.003,3.437,0.35,6.812,1.209,10.092l1.265,4.813l4.727,1.578
     c3.607,1.198,7.344,1.685,11.079,1.685c5.67-0.006,11.376-1.103,17.085-2.871c8.552-2.665,17.138-6.873,25.276-11.905
     c8.13-5.038,15.784-10.897,22.31-17.009c8.449-7.914,14.994-16.942,19.472-26.351c4.472-9.419,6.898-19.236,6.904-28.802
     c0-4.394-0.52-8.737-1.617-12.924L373.027,366.287z M352.393,404.923c-3.442,7.243-8.627,14.452-15.436,20.832
     c-7.402,6.946-17.006,13.875-26.555,18.873c-4.769,2.508-9.52,4.534-13.914,5.899c-4.335,1.355-8.304,2.026-11.5,2.038
     c0.02-3.566,0.666-7.931,1.945-12.627c1.95-7.204,5.339-15.146,9.627-22.484c4.278-7.343,9.47-14.098,14.773-19.058
     c6.364-5.96,13.366-10.466,20.342-13.444c6.974-2.983,13.914-4.432,20.104-4.427c1.942,0,3.809,0.146,5.586,0.414
     c0.087,1.008,0.148,2.032,0.148,3.084C357.52,390.427,355.841,397.68,352.393,404.923z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M256.55,60.168c3.808,0,6.898-3.09,6.898-6.901V6.896c0-3.806-3.09-6.896-6.898-6.896
     c-3.809,0-6.898,3.09-6.898,6.896v46.372C249.652,57.079,252.741,60.168,256.55,60.168z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M149.243,155.592c3.812,0,6.899-3.084,6.899-6.901v-46.752c0-3.812-3.087-6.896-6.899-6.896
     c-3.808,0-6.898,3.084-6.898,6.896v46.752C142.345,152.508,145.435,155.592,149.243,155.592z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M362.706,155.592c3.811,0,6.898-3.084,6.898-6.901v-46.752c0-3.812-3.087-6.896-6.898-6.896
     c-3.809,0-6.898,3.084-6.898,6.896v46.752C355.808,152.508,358.897,155.592,362.706,155.592z"
        />
        <path
          fill="#404040"
          className="st0"
          d="M256.357,210.779c-3.49,0-6.322,2.832-6.322,6.325v288.572c0,3.492,2.832,6.324,6.322,6.324
     c3.493,0,6.324-2.832,6.324-6.324V217.104C262.681,213.611,259.85,210.779,256.357,210.779z"
        />
      </g>
    </svg>
  );
};

export default WheatIcon;
